<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 权限管理 -->
  <section class="page-container">
    <div class="table-box">
      <!-- 搜索表单 -->
      <!-- <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="权限名称：">
          <el-input
            v-model="searchForm.name"
            placeholder="权限名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form> -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="handleShowDialog(0)"
            >
              添加顶级
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data no-page" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            ref="tableBox"
            border
            style="width: 100%"
            row-key="id"
            default-expand-all
            :max-height="tableHeight"
            :data="tableData"
            :tree-props="treeProps"
          >
            <!-- <el-table-column type="index" width="50" align="center" label="序号" /> -->

            <!-- 改变箭头所在列，把想要设置展开行的列的前面的所有没有type的设置一个type=""-->
            <el-table-column
              type=""
              prop="id"
              width="50"
              align="center"
              label="ID"
            />
            <el-table-column
              prop="name"
              label="权限名称"
              min-width="180"
              align="left"
            />
            <el-table-column
              prop="route_url"
              label="后端路由地址"
              width="220"
              align="center"
            />
            <el-table-column
              prop="front_icon"
              label="图标"
              width="220"
              align="center"
            />
            <el-table-column
              prop="front_route_url"
              label="前端路由地址"
              width="220"
              align="center"
            />
            <el-table-column
              prop="front_route_name"
              label="前端路由名称"
              width="220"
              align="center"
            />
            <el-table-column label="权限类型" width="100" align="center">
              <template slot-scope="scope">
                <el-tag
                  :type="(scope.row.type == '1' && 'success') || ''"
                  v-if="scope.row.type"
                >
                  {{ (scope.row.type == "1" && "目录") || "权限项" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
              width="80"
              align="center"
            />
            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-plus"
                  @click="handleShowDialog(scope.row.id, scope.row.type)"
                >
                  添加子级
                </el-button>
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  @click="getDetail(scope.row.id)"
                >
                  编辑
                </el-button>
                <el-button
                  class="color-danger"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 新增/编辑表单弹窗 -->
      <el-dialog
        :title="(id && '修改权限') || '添加权限'"
        :visible.sync="formDialogVisible"
        width="750px"
        @close='handleCancle'
      >
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          label-width="110px"
        >
          <el-form-item label="上级权限:">
            <el-select v-model="formData.pid" disabled>
              <el-option label="无" :value="0" />
              <el-option
                v-for="item in menuListSearch"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <p>
                  <template v-if="item.hierarchy > 0">
                    <span
                      class="hierarchy iconfont"
                      v-for="num in item.hierarchy"
                      :key="num"
                    >
                      &#xe603;
                    </span>
                  </template>
                  {{ item.label }}
                </p>
              </el-option>
            </el-select>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="权限名称:" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入权限名称"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="权限类型:" prop="type">
                <el-radio-group v-model="formData.type">
                  <el-radio :label='1'>目录</el-radio>
                  <el-radio :label='2'>权限项</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="前台图标:" prop="front_icon">
                <el-input
                  v-model="formData.front_icon"
                  placeholder="请输入前台图标（字符串）"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="前端路由地址:" prop="front_route_url">
                <el-input
                  v-model="formData.front_route_url"
                  placeholder="请输入前端路由地址"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="前端路由名称:" prop="front_route_name">
                <el-input
                  v-model="formData.front_route_name"
                  placeholder="请输入前端路由名称"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="formData.type == 2">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="模块名称:" prop="module_name">
                  <el-input
                    v-model="formData.module_name"
                    placeholder="请输入模块名称"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="控制器名称:" prop="controller_name">
                  <el-input
                    v-model="formData.controller_name"
                    placeholder="请输入控制器名称"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="操作名称:" prop="action_name">
                  <el-input
                    v-model="formData.action_name"
                    placeholder="请输入操作名称"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="后端路由地址:" prop="route_url">
                  <el-input
                    v-model="formData.route_url"
                    placeholder="请输入后端路由地址"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <el-form-item label="排序:" prop="sort">
            <el-input-number v-model="formData.sort" :min="1" />
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="formData.remarks"
              placeholder="请输入权限备注"
              type="textarea"
              :rows="5"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle">取 消</el-button>
          <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapState,mapActions } from "vuex";
import { roleList, roleUpdate, roleAdd, roleDelete } from "@api/authority";
export default {
  name: "authority",
  inject: ["reload"],
  data() {
    return {
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      treeProps: { children: "_child", hasChildren: "hasChildren" }, //树型表格配置项
      id: null, //修改部门id
      formDialogVisible: false, //修改/添加表单弹窗显示
      formData: {
        type: 1,
        sort: 200,
      }, //表单存储数据
      rules: {
        name: [{ required: true, message: "请输入权限名称", trigger: "blur" }],
        type: [
          { required: true, message: "请输入选择权限类型", trigger: "change" },
        ],
        routerLink: [
          { required: true, message: "请输入路由地址", trigger: "blur" },
        ],
        routerName: [
          { required: true, message: "请输入路由名称", trigger: "blur" },
        ],
        modularName: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        controllerName: [
          { required: true, message: "请输入控制器名称", trigger: "blur" },
        ],
        operationName: [
          { required: true, message: "请输入操作名称", trigger: "blur" },
        ],
        backRouter: [
          { required: true, message: "请输入后端路由名称", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请选择部门排序", trigger: "change" },
        ],
      }, //表单校验规则
    };
  },
  computed: {
    ...mapState("system", ["menuListSearch","menuTreeList"]),
  },
  created() {
    // 获取初始化数据
    this.getData();
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
  },
  methods: {
    ...mapActions("system", ["getMenuList"]),
    /** 获取表格数据 */
    async getData() {
      /**
       * 请求接口数据
       */
      await this.getMenuList();
      this.tableData = this.menuTreeList;
      this.$forceUpdate();
    },
    /** 查询 */
    handleSearch() {
      this.getData();
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    handleShowDialog(id, type = 1) {
      this.formData = {
        ...this.formData,
        pid: id,
        type: type,
      };
      console.log(this.formData);
      this.formDialogVisible = true;
    },
    /** 修改前获取详情信息 */
    async getDetail(id) {
      this.id = id;
      /**
       * 请求接口获取详情数据(权限详情)
       */
      let res = await roleUpdate({ id: this.id }, "get");
      res.data.type=Number(res.data.type);
      this.formData = {
        ...this.formData,
        ...res.data,
      };

      // 数据请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    handleCancle() {
      this.formDialogVisible = false;
      this.formData = {
        type: 1,
        sort: 200,
      };
      this.id = null;
      // this.$refs["formData"].clearValidate();
    },
    handleSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口，修改密码
           */
          let res = null;
          if (this.id) {
            // 存在id，执行编辑请求
            res = await roleUpdate({ ...this.formData }, "post");
          } else {
            // 不存在id，执行添加请求
            res = await roleAdd({ ...this.formData });
          }
          // 请求后，不论是否成功，调用方法关闭弹窗
          this.handleCancle();
          // 请求成功后，调用方法，更新页面数据
          this.getData();
        }
      });
    },
    /** 删除 */
    handleDelete(id) {
      /** 弹窗再次确认操作 */
      this.$confirm("此操作将永久删除该权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          /**
           * 请求接口删除数据
           */
          let res = await roleDelete(id);
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "权限已删除!",
            });
          }

          this.getData();
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除操作",
          });
        });
    },
  },
};
</script>